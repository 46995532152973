import { useState, useCallback, useContext, useMemo } from "react";
import Commands from "../../model/Command";
import {
  Grid,
  Typography,
  Box,
  MenuItem,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from "@mui/material";
import useAxios from "../../hooks/useAxios";
import { baseUrl } from "../../constants";
import {
  DataGrid,
  GridCallbackDetails,
  GridCellParams,
  GridColDef,
  GridSelectionModel,
  GridSortModel,
} from "@mui/x-data-grid";
import SectorContext from "../../context/SectorContext";

interface CommandEntry {
  id: string;
  index: number;
  command: string;
  lastModified: string | null;
}

interface CommandTarget {
  id: string;
  target: string;
  lastModified: string;
}

interface PaginatedResult {
  results: Array<CommandTarget>;
  pageNumber: number;
  pageSize: number;
  totalCount: number;
}
const CommandGenerator = () => {
  const [command, setCommand] = useState();
  const [activity, setOnlyActive] = useState("active");
  const [rampMode, setRampMode] = useState(false);
  const [queryOptions, setQueryOptions] = useState<GridSortModel>([]);
  const [currentPage, setCurrentPage] = useState(0);

  const [axiosInstance] = useAxios();
  const [targets, setTargets] = useState<Array<CommandEntry>>([]);
  const [totalCount, setTotalCount] = useState(0);
  const { sector } = useContext(SectorContext);

  const handleCommandChange = (event: any) => {
    setCommand(event.target.value);
  };

  const handleSetActiveChange = (event: any) => {
    setOnlyActive(event.target.value);
  };

  const handleGetCommands = useCallback(
    async (
      page = 0,
      orderby: string | undefined = undefined,
      direction: string | undefined = undefined
    ) => {
      const results = await axiosInstance.get<PaginatedResult>(
        `${baseUrl}/targets`,
        {
          params: {
            command,
            activity,
            orderby: capitalize(orderby),
            direction,
            take: 50,
            skip: page * 50,
            idSector: sector,
          },
        }
      );
      setTotalCount(results.data.totalCount);
      setTargets(
        results.data.results.map((target, index) => ({
          id: target.id,
          index,
          command: `${rampMode ? "deck " : ""}${command} ${target.target}`,
          lastModified: target.lastModified
            ? new Date(target.lastModified).toLocaleDateString()
            : null,
        }))
      );
    },
    [command, activity, rampMode, axiosInstance]
  );

  const CommandColumnDefinition: GridColDef[] = [
    {
      field: "command",
      headerName: "Commande",
      width: 200,
      resizable: true,
      editable: false,
      filterable: false,
    },
    {
      field: "lastModified",
      headerName: "Dernière Modification",
      width: 200,
      resizable: true,
      editable: false,
      disableExport: true,
      filterable: false,
    },
  ];
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

  const handleSelectionChange = (params: GridCellParams, event: any) => {
    if (event.key === "ArrowDown" && params.row.index !== targets.length - 1) {
      const newSelection = targets[params.row.index + 1].id;
      setSelectionModel([newSelection]);
    }

    if (event.key === "ArrowUp" && params.row.index !== 0) {
      const newSelection = targets[params.row.index - 1].id;
      setSelectionModel([newSelection]);
    }
  };

  const handleSortModelChange = useCallback(
    (sortModel: GridSortModel) => {
      // Here you save the data you need from the sort model
      setQueryOptions(sortModel);
      handleGetCommands(
        currentPage,
        getMainField(command!, sortModel[0]?.field),
        sortModel[0]?.sort?.toString()
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [currentPage, command, handleGetCommands]
  );

  const getMainField = (command: string, field: string) => {
    if (field !== "command") return field;
    return command === "connect" ? "LotId" : "Nom";
  };

  const capitalize = (field: string | undefined) => {
    if (!field?.length) return "";
    return field.charAt(0).toUpperCase() + field.slice(1);
  };

  const handlePageChange = (page: number, details: GridCallbackDetails) => {
    handleGetCommands(
      page,
      getMainField(command!, queryOptions[0]?.field ?? ""),
      queryOptions[0]?.sort?.toString() ?? ""
    );
    setCurrentPage(page);
  };

  const handleCheckBoxChange = (event: any) => {
    setRampMode(event.target.checked);
  };

  return (
    <Box>
      <Grid container spacing={1} sx={{ margin: "3rem" }}>
        <Grid item xs={12}>
          <Typography variant="h5">Génération de commandes</Typography>
        </Grid>
        <Grid item xs={2}>
          <TextField
            value={command}
            variant="standard"
            select
            fullWidth
            onChange={handleCommandChange}
            label="Commande"
          >
            {Object.keys(Commands)
              .filter((v) => isNaN(Number(v)))
              .map((x) => {
                return (
                  <MenuItem key={x} value={x}>
                    {x}
                  </MenuItem>
                );
              })}
          </TextField>
        </Grid>
        {command !== "connect" && (
          <Grid item xs={2}>
            <TextField
              value={activity}
              variant="standard"
              select
              fullWidth
              onChange={handleSetActiveChange}
              label="Activité"
            >
              <MenuItem value="all">Tous</MenuItem>
              <MenuItem value="active">Actifs Seulement</MenuItem>
              <MenuItem value="placeholder">Placeholders</MenuItem>
            </TextField>
          </Grid>
        )}
        <Grid item xs={6}>
          <FormGroup>
            <FormControlLabel
              sx={{ marginLeft: "0.5rem", marginTop: "0.65rem" }}
              control={
                <Checkbox checked={rampMode} onChange={handleCheckBoxChange} />
              }
              label="Mode Rampe"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12}>
          <Button
            sx={{ marginLeft: "-0.5rem" }}
            onClick={() =>
              handleGetCommands(
                currentPage,
                getMainField(command!, queryOptions[0]?.field ?? ""),
                queryOptions[0]?.sort?.toString() ?? ""
              )
            }
          >
            Générer
          </Button>
        </Grid>
        <Grid item xs={12}></Grid>
      </Grid>
      {targets.length > 0 && (
        <Box>
          <DataGrid
            sx={{ margin: "3rem", marginTop: 0, height: "40rem" }}
            rows={targets}
            columns={CommandColumnDefinition}
            selectionModel={selectionModel}
            paginationMode="server"
            rowCount={totalCount}
            pageSize={50}
            rowHeight={38}
            onCellKeyDown={handleSelectionChange}
            sortingMode="server"
            onSortModelChange={handleSortModelChange}
            onSelectionModelChange={setSelectionModel}
            onPageChange={handlePageChange}
          />
        </Box>
      )}
    </Box>
  );
};

export default CommandGenerator;
