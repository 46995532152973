import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
} from "@mui/material";
import { Poste } from "../../model/Poste";
import { baseUrl } from "../../constants";
import useAxios from "../../hooks/useAxios";

interface UserAddModalProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  batimentId: number;
  handleAddMember: (poste: Poste) => void;
}

interface AutoCompleteEntry {
  label: string;
  value: number;
}

function UserAddModal({
  open,
  setOpen,
  batimentId,
  handleAddMember,
}: UserAddModalProps): JSX.Element {
  const [name, setName] = useState<string | undefined>("");
  const [role, setRole] = useState("");
  const [axiosInstance] = useAxios();
  const [autoCompleteList, setAutoCompleteList] = useState<AutoCompleteEntry[]>(
    []
  );

  const handleClose = () => {
    setName("");
    setRole("");
    setOpen(false);
  };

  useEffect(() => {
    if (!open) return;
    axiosInstance
      .get<AutoCompleteEntry[]>(`${baseUrl}/personnages/autocomplete?take=25`)
      .then((response) => setAutoCompleteList(response.data));
  }, [open, axiosInstance]);

  const AddMember = async () => {
    const result = await axiosInstance.post<Poste>(`${baseUrl}/poste`, {
      batimentId,
      nomPoste: role,
      nomPersonnage: name,
    });
    if (result) handleClose();
    handleAddMember(result.data);
  };

  const handleAutoCompleteChange = (event: any) => {
    axiosInstance
      .get<AutoCompleteEntry[]>(
        `${baseUrl}/personnages/autocomplete?take=25&query=${event.target.value}`
      )
      .then((response) => setAutoCompleteList(response.data));
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <Typography>Ajouter un membre</Typography>
      </DialogTitle>
      <DialogContent>
        <Box>
          <Autocomplete
            disablePortal
            options={autoCompleteList}
            sx={{ width: "20rem" }}
            onChange={(evt, newValue) => setName(newValue?.label)}
            renderInput={(params) => (
              <TextField
                {...params}
                onChange={handleAutoCompleteChange}
                variant="standard"
                label="Nom du membre"
              />
            )}
          />
          <TextField
            label="Role du membre"
            fullWidth
            placeholder="Role"
            variant="standard"
            value={role}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setRole(event.target.value)
            }
          ></TextField>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={AddMember} color="primary">
          Confirmer
        </Button>
        <Button onClick={handleClose} color="primary">
          Annuler
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UserAddModal;
