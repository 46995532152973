import {
  TextField,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  FormControlLabel,
  Switch,
  Button,
  Autocomplete,
  Grid,
} from "@mui/material";
import React, { SyntheticEvent, useContext, useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./LogementScreen.css";
import { SousStructure } from "../../../model/SousStructure";
import { Logement } from "../../../model/models";
import { Proprietaire } from "../../../model/Proprietaire";
import ZoneSelector from "../../common/ZoneSelector";
import { baseUrl } from "../../../constants";
import useAxios from "../../../hooks/useAxios";
import SectorContext from "../../../context/SectorContext";

interface LogementScreenProps {
  batiment: Logement;
  handleChange: any;
  setFieldValue: any;
}

interface AutoCompleteEntry {
  label: string;
  value: number;
}

function LogementScreen({
  batiment,
  handleChange,
  setFieldValue,
}: LogementScreenProps) {
  const [expanded, setExpanded] = React.useState("");
  const [autoCompleteList, setAutoCompleteList] = useState<AutoCompleteEntry[]>(
    []
  );
  const [axiosInstance] = useAxios();

  const handleChangePanel =
    (panel: string) => (event: unknown, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : "");
    };

  const { sector } = useContext(SectorContext);


  useEffect(() => {
    axiosInstance
      .get<AutoCompleteEntry[]>(`${baseUrl}/personnages/autocomplete?take=25&idSector=${sector}`)
      .then((response) => setAutoCompleteList(response.data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeleteClick = (ss: SyntheticEvent) => {
    if (batiment !== undefined) {
      const index = Number(ss.currentTarget.id.replace("delete_", ""));
      setFieldValue(
        "batiment.sousStructures",
        batiment?.sousStructures.filter((x: SousStructure, i) => i !== index)
      );
    }
  };

  const formatProprietaires = (proprietaires: Array<Proprietaire>) => {
    return proprietaires.map((x) => `${x.proprietaire} x${x.count}`).join(",");
  };

  const handleAddClick = (ss: SyntheticEvent) => {
    if (batiment !== undefined) {
      setFieldValue(
        "batiment.sousStructures",
        batiment.sousStructures.concat({
          numero: -1,
          digicode: "*******",
          zones: Array.from({ length: 9 }, (i) => (i = false)),
        })
      );
    }
  };

  const handleAutoCompleteChange = (event: any) => {
    axiosInstance
      .get<AutoCompleteEntry[]>(
        `${baseUrl}/personnages/autocomplete?take=25&query=${event.target.value}&idSector=${sector}`
      )
      .then((response) => setAutoCompleteList(response.data));
  };

  const handleProprietaireChange = (
    newValue: AutoCompleteEntry | null,
    index: number
  ) => {
    if (newValue === null) {
      setFieldValue(`batiment.sousStructures.${index}.proprietaire`, undefined);
    } else {
      setFieldValue(`batiment.sousStructures.${index}.proprietaire`, {
        id: newValue.value,
        nom: newValue.label,
      });
    }
  };

  const handleLocataireChange = (
    newValue: AutoCompleteEntry | null,
    index: number
  ) => {
    if (newValue === null) {
      setFieldValue(`batiment.sousStructures.${index}.locataire`, undefined);
    } else {
      setFieldValue(`batiment.sousStructures.${index}.locataire`, {
        id: newValue.value,
        nom: newValue.label,
      });
    }
  };

  const handleSwitchChange = (event: any) => {
    const name = event.target.name;
    const checked = event.target.checked;
    setFieldValue(name, checked);
  };

  return (
    <div>
      {" "}
      {batiment?.sousStructures.map((ss: SousStructure, index: number) => {
        return (
          <Accordion
            expanded={expanded === `panel${index}`}
            onChange={handleChangePanel(`panel${index}`)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography>
                Sous-Structure #{index + 1} {}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <Autocomplete
                    disablePortal
                    defaultValue={
                      {
                        label: ss.proprietaire?.nom ?? "",
                        value: ss.proprietaire?.id,
                      } as AutoCompleteEntry
                    }
                    options={autoCompleteList}
                    onChange={(evt, newValue) =>
                      handleProprietaireChange(newValue, index)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name={`batiment.sousStructures.${index}.proprietaire.nom`}
                        onChange={handleAutoCompleteChange}
                        variant="standard"
                        label="Propriétaire"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Autocomplete
                    disablePortal
                    defaultValue={
                      {
                        label: ss.locataire?.nom ?? "",
                        value: ss.locataire?.id,
                      } as AutoCompleteEntry
                    }
                    options={autoCompleteList}
                    onChange={(evt, newValue) =>
                      handleLocataireChange(newValue, index)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name={`batiment.sousStructures.${index}.locataire.nom`}
                        onChange={handleAutoCompleteChange}
                        variant="standard"
                        label="Locataire"
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    label="Digicode"
                    variant="standard"
                    name={`batiment.sousStructures.${index}.digicode`}
                    onChange={handleChange}
                    value={ss.digicode}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={
                          batiment.sousStructures[index].isDigicodeCondor
                        }
                        name={`batiment.sousStructures.${index}.isDigicodeCondor`}
                        onChange={handleSwitchChange}
                        color="primary"
                      />
                    }
                    label="Condor"
                    labelPlacement="start"
                    className="switch"
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={batiment.sousStructures[index].hasCamera}
                        onChange={handleSwitchChange}
                        name={`batiment.sousStructures.${index}.hasCamera`}
                        color="primary"
                      />
                    }
                    label="Camera"
                    labelPlacement="start"
                    className="switch"
                  />
                </Grid>
                <Grid
                  item
                  xs={3}
                  sx={{ marginLeft: "1rem", marginTop: "0.75rem" }}
                >
                  {/* <Link>Présences</Link> */}
                </Grid>
                <Grid item xs={12}>
                  <ZoneSelector
                    name={`batiment.sousStructures.${index}.zones`}
                    value={batiment.sousStructures[index].zones ?? []}
                    setFieldValue={setFieldValue}
                  />
                </Grid>
                <br />

                <Button id={`delete_${index}`} onClick={handleDeleteClick}>
                  Retirer
                </Button>
              </Grid>
            </AccordionDetails>
          </Accordion>
        );
      })}{" "}
      <Button onClick={handleAddClick}>Ajouter</Button>{" "}
      <Typography>{`Propriétaires : ${formatProprietaires(
        batiment.proprietaires
      )}`}</Typography>
    </div>
  );
}

export default LogementScreen;
